<script>
export default {
    name: 'DataViewBase',
    methods: {
        dataLoadHelper (apiCallback, responseDataContainerCallback, errorMessage, loadingIndicatorCallback) {
            loadingIndicatorCallback(true)
            apiCallback().then((response) => {
                responseDataContainerCallback(response.data)
            }).catch((error) => {
                console.error(error)
                this.$notify.error(errorMessage)
            }).finally(() => {
                this.$nextTick(() => {
                    loadingIndicatorCallback(false)
                })
            })
        },
        crudOperationHelper (
            apiCallback, responseCallback, loadingIndicatorCallback, errorMessage = 'Záznam se nepodařilo uložit') {
            loadingIndicatorCallback(true)
            apiCallback().then((response) => {
                responseCallback(response)
            }).catch((error) => {
                console.error(error)
                this.$notify.error(errorMessage)
            }).finally(() => {
                this.$nextTick(() => {
                    loadingIndicatorCallback(false)
                })
            })
        }
    }
}
</script>
