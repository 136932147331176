<template>
    <div class="container-fluid my-4">
        <h1 class="mb-2">{{$t('clientSessions.view.title')}}</h1>
        <div class="bg-white shadow p-4 rounded">
            <loader-overlay
                :loading="saving"
            >
                <client-session-table
                    :dataset="clientSessions"
                    :loading="clientSessionsLoading"
                    @show="onShow"
                />
            </loader-overlay>
        </div>
    </div>
</template>

<script>
import DataViewBase from '../../../../../../Components/DataViewBase.vue'
import ClientSessionTable from './Components/ClientSessionTable.vue'
export default {
    name: 'ClientSessionsList',
    extends: DataViewBase,
    components: {
        ClientSessionTable
    },
    data () {
        return {
            clientSessions: [],
            clientSessionsLoading: false,
            saving: false
        }
    },
    mounted () {
        this.loadClientSessions()
    },
    methods: {
        loadClientSessions () {
            this.clientSessionsLoading = true
            this.$api.sessions.read()
                .then((response) => {
                    this.clientSessions = response.data
                })
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('clientSessions.view.error.loadSessions'))
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.clientSessionsLoading = false
                    })
                })
        },
        onShow (clientSession) {
            this.$router.push({ name: 'AdminClientSessionDetail', params: { sessionId: clientSession._id } })
        }
    }
}
</script>
