<script>
export default {
    name: 'DataTableBase',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return []
        },
        buttons () {
            return []
        },
        lang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        }
    },
    methods: {
        onAction (event) {
            this.$emit(event.event, event.row)
        }
    }
}
</script>
